import React, { useState, useEffect } from "react";

import {
  CalendarOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { ReadFilled } from "@ant-design/icons";
import { Button, Badge, Card } from "antd";

function AppNews() {
  const [size] = useState("middle"); // default is 'middle'
  const [eventData, setEventData] = useState(null); // Define eventData and setEventData

  useEffect(() => {
    fetch("/event_news.json")
      .then((response) => response.json())
      .then((data) => setEventData(data))
      .catch((error) => console.error("Error loading event news:", error));
  }, []);

  if (!eventData) {
    return <div>Loading...</div>;
  }

  return (
    <div id="news" className="block aboutBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2>Aktuelle Ankündigung</h2>
        </div>
        <Badge.Ribbon text="Neu" color="red">
          <Card>
            <div className="contentHolder">
              <h2>{eventData.title}</h2>
              <p>{eventData.short_description}</p>
              <p>
                <CalendarOutlined style={{ marginRight: 8 }} />
                <strong>Datum:</strong> {eventData.date}{" "}
                <ClockCircleOutlined
                  style={{ marginRight: 8, marginLeft: 16 }}
                />
                <strong>Uhrzeit:</strong> {eventData.time}
              </p>
              <p>
                <EnvironmentOutlined style={{ marginRight: 8 }} />
                <strong>Ort:</strong> {eventData.location}
              </p>
              <div className="buttonHolder">
                <a href={eventData.link} target="_blank" rel="noreferrer">
                  <Button type="primary" icon={<ReadFilled />} size={size}>
                    Weitere Informationen auf meine.stimme
                  </Button>
                </a>
              </div>
            </div>
          </Card>
        </Badge.Ribbon>
      </div>
    </div>
  );
}

export default AppNews;
