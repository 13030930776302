import React, { useState, useEffect } from "react";
import AppHero from "../modules/hero";
import AppNews from "../modules/news";
import AppAbout from "../modules/about";
import AppConductor from "../modules/conductor";
import AppModal from "../modules/modal";
import AppJoin from "../modules/join";
import AppBoard from "../modules/board";
import AppContact from "../modules/contact";

function AppStart() {
  const [showNews, setShowNews] = useState(false);

  useEffect(() => {
    fetch("/event_news.json")
      .then((response) => response.json())
      .then((data) => {
        const currentDate = new Date();
        const displayFrom = new Date(data.display_notice_from);
        const displayUntil = new Date(data.display_notice_until);
        if (currentDate >= displayFrom && currentDate < displayUntil) {
          setShowNews(true);
        }
      })
      .catch((error) => console.error("Error loading event news:", error));
  }, []);

  return (
    <div className="main">
      <AppHero />
      {showNews && <AppNews />}
      <AppAbout />
      <AppConductor />
      <AppModal />
      <AppJoin />
      <AppBoard />
      <AppContact />
    </div>
  );
}

export default AppStart;
